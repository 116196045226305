import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Audio } from "react-loader-spinner";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  useGetLogList,
  useSummaryActivitiesAll,
  useSummaryActivitiesLogs,
  useSummaryData,
  useSummaryLessonsAll,
  useSummaryLessonsLogs,
  useSummaryLogs,
} from "../services/Query";
import TokenServices from "../services/TokenServices";
import { UserInfo } from "../stores";
import { useForm } from "react-hook-form";
const LoadingBox = () => {
  return (
    <div className="d-flex justify-content-center mb-2">
      <Audio
        height="100"
        width="100"
        color="#4fa94d"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  );
};
type summaryFrm = {
  loginSData: string;
  loginEDate: string;
  loginKind: string;
};
export const Overview = () => {
  const {
    register,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isDirty, errors, isValid },
  } = useForm<summaryFrm>({
    reValidateMode: "onChange",
  });
  useEffect(() => {
    reset({
      loginSData: moment().add(-7, "days").format("YYYY-MM-DD"),
      loginEDate: moment().format("YYYY-MM-DD"),
      loginKind: "1",
    });
  }, []);
  const { loginEDate, loginSData, loginKind } = watch();
  const { data: summary, isLoading } = useSummaryData(
    moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
    moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
  );
  const {
    data: summaryData,
    isLoading: isLoadingSummary,
    isRefetching: isRefetchingSummary,
  } = useSummaryLogs(
    moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
    moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
  );
  const { data: lessonsReport, isLoading: isLessonsLoading } =
    useSummaryLessonsLogs(
      moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
      moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
    );
  const { data: activitiesReport, isLoading: isActivitiesLoading } =
    useSummaryActivitiesLogs(
      moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
      moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
    );
  const { data: lessonsReportAll, isLoading: isLessonAllLoading } =
    useSummaryLessonsAll(
      moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
      moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
    );
  const { data: activitiesReportAll, isLoading: isActivitiesAllLoading } =
    useSummaryActivitiesAll(
      moment(loginSData).add(1, "days").format("YYYY-MM-DD"),
      moment(loginEDate).add(1, "days").format("YYYY-MM-DD")
    );
  const logData = useMemo(() => {
    if (summaryData) {
      const data = summaryData.summaryLogs
        .sort(
          (a, b) => new Date(a.title).getTime() - new Date(b.title).getTime()
        )
        .map((it) => {
          const key = moment(it.title).format("MM/DD");
          const count = it.count;
          return {
            key,
            count,
          };
        });
      return data;
    }
    return undefined;
  }, [summaryData]);
  const lessonsData = useMemo(() => {
    if (lessonsReport) {
      const data = lessonsReport.summaryLogs
        .sort(
          (a, b) => new Date(a.title).getTime() - new Date(b.title).getTime()
        )
        .map((it) => {
          const key = moment(it.title).format("MM/DD");
          const count = it.count;
          return {
            key,
            count,
          };
        });
      return data;
    }
    return undefined;
  }, [lessonsReport]);
  const activitiesData = useMemo(() => {
    if (activitiesReport) {
      const data = activitiesReport.summaryLogs
        .sort(
          (a, b) => new Date(a.title).getTime() - new Date(b.title).getTime()
        )
        .map((it) => {
          const key = moment(it.title).format("MM/DD");
          const count = it.count;
          return {
            key,
            count,
          };
        });
      return data;
    }
    return undefined;
  }, [activitiesReport]);
  const loginKindName = useMemo(() => {
    let kindName = "Day";
    switch (loginKind) {
      case "1":
        kindName = "Day";
        break;
      case "2":
        kindName = "Date";
        break;
      case "3":
        kindName = "Month";
        break;
    }
    return kindName;
  }, [loginKind]);
  return (
    <div>
      <div className="d-grid gap-3">
        <div className="row">
          <div className="col-3">
            <Form.Group className="date_cell">
              <Form.Label className="fw-bold">Start Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="09/01/2022"
                {...register("loginSData", { valueAsDate: true })}
              />
            </Form.Group>
          </div>
          <div className="col-3">
            <Form.Group className="date_cell">
              <Form.Label className="fw-bold">End Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="09/01/2022"
                {...register("loginEDate", { valueAsDate: true })}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {(isLoadingSummary || isRefetchingSummary) && <LoadingBox />}
            {logData && (
              <div className="d-flex">
                <LineChart
                  width={500}
                  height={400}
                  data={logData}
                  margin={{
                    top: 30,
                    right: 10,
                    left: 10,
                    bottom: 10,
                  }}
                >
                  <XAxis dataKey="key">
                    <Label
                      value={loginKindName}
                      position={"insideBottomRight"}
                      className="fg-medium"
                      offset={-5}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      angle={270}
                      dx={-10}
                      value={"login count"}
                      className="fg-medium"
                    />
                  </YAxis>
                  <Tooltip />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line dataKey="count" stroke="#82ca9d" />
                </LineChart>
              </div>
            )}
            {(isLessonsLoading || isRefetchingSummary) && <LoadingBox />}
            {lessonsData && (
              <div className="d-flex">
                <LineChart
                  width={500}
                  height={400}
                  data={lessonsData}
                  margin={{
                    top: 30,
                    right: 10,
                    left: 10,
                    bottom: 10,
                  }}
                >
                  <XAxis dataKey="key">
                    <Label
                      value={loginKindName}
                      position={"insideBottomRight"}
                      className="fg-medium"
                      offset={-5}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      angle={270}
                      dx={-10}
                      value={"lesson usage count"}
                      className="fg-medium"
                    />
                  </YAxis>
                  <Tooltip />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line dataKey="count" stroke="#82ca9d" />
                </LineChart>
              </div>
            )}
            {(isActivitiesLoading || isRefetchingSummary) && <LoadingBox />}
            {activitiesData && (
              <div className="d-flex">
                <LineChart
                  width={500}
                  height={400}
                  data={activitiesData}
                  margin={{
                    top: 30,
                    right: 10,
                    left: 10,
                    bottom: 10,
                  }}
                >
                  <XAxis dataKey="key">
                    <Label
                      value={loginKindName}
                      position={"insideBottomRight"}
                      className="fg-medium"
                      offset={-5}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      angle={270}
                      dx={-10}
                      value={"activity usage count"}
                      className="fg-medium"
                    />
                  </YAxis>
                  <Tooltip />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line dataKey="count" stroke="#82ca9d" />
                </LineChart>
              </div>
            )}
          </div>
          <div className="col-6 d-grid gap-2">
            <div>
              <h6>
                total lesson count : <span>{summary?.totalLessonCount}</span>
              </h6>
              <h6>
                total activity count :{" "}
                <span>{summary?.totalActivityCount}</span>
              </h6>
              <h6>
                lesson usage count : <span>{summary?.readLesson}</span>
              </h6>
              <h6>
                activity usage count : <span>{summary?.readActivity}</span>
              </h6>
            </div>
            <div>
              <h6>Lesson Summary</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Lesson Title</th>
                    <th>count</th>
                  </tr>
                </thead>
                <tbody>
                  {lessonsReportAll?.summaryLogs.slice(0, 8).map((it) => (
                    <tr key={it.title}>
                      <td>{it.title}</td>
                      <td>{it.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <h6>Activity Summary</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Activity Title</th>
                    <th>count</th>
                  </tr>
                </thead>
                <tbody>
                  {activitiesReportAll?.summaryLogs.slice(0, 8).map((it) => (
                    <tr key={it.title}>
                      <td>{it.title}</td>
                      <td>{it.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
