import { AxiosError } from "axios";
import React from "react";
import { Card } from "react-bootstrap";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { TopHeader } from "../components/TopHeader";
import { useGSignin } from "../services/Query";
import TokenServices from "../services/TokenServices";
import { token, UserInfo } from "../stores";

export const Login = () => {
  const app_client_id = process.env.REACT_APP_CLIENT_ID;
  const navigate = useNavigate();
  const { isLoading: isSignin, mutate: onGSignin } = useGSignin();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("profileObj" in response) {
      const profile = response.profileObj;
      const data = {
        email: profile.email,
        firstName: profile.givenName,
        lastName: profile.familyName,
        googleId: profile.googleId,
      };
      onGSignin(JSON.stringify(data), {
        onSuccess: (res) => {
          const { token, member } = res;

          setUserinfo(member);
          TokenServices.updateLocalAccessToken(token);
          navigate("/home/users");
        },
        onError: (err) => {
          alert("Error!");
        },
      });
    } else {
    }
  };
  return (
    <>
      <TopHeader isLogoutable={false} />
      <div className="w-100 d-flex align-items-center align-middle wrap_100">
        <Card className="sign_card mx-auto">
          <Card.Header>log in</Card.Header>
          <Card.Body className="align-items-center d-flex">
            <GoogleLogin
              clientId={app_client_id ?? ""}
              className="w_auto mx-auto"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              isSignedIn={false}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
